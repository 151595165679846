<template>
    <div class="app-summary-details">
        <app-loader v-if="loading" />

        <div class="header" v-if="show_header">
            <div class="title">{{ title }}</div>

            <div class="total">{{ total }}</div>
        </div>

        <div class="details">
            <div class="detail" v-for="(row, index) in rows" :key="index">
                <template v-if="isObject(row.key)">
                    <div class="status" :class="row.key.status">
                        {{ row.key.text }}
                    </div>
                </template>
                <template v-else>
                    {{ row.key }}
                </template>

                <div class="value">
                    {{ row.value }}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import appLoader from '@/components/app-loader'

export default {
    props: {
        title: { type: String, default: null },

        total: { type: [String, Number], default: null },

        rows: { type: Array, required: true },

        loading: { type: Boolean, default: false },
    },

    components: {
        appLoader,
    },

    methods: {
        isObject(value) {
            return typeof value == 'object' && value != null
        },
    },

    computed: {
        show_header() {
            // console.group('show_header')
            //     console.log('this.title', this.title)
            //     console.log('this.total', this.total)
            // console.groupEnd()

            return this.title || this.total || this.total == 0
        },
    },
}
</script>

<style lang="scss">
.app-summary-details {
    position: relative;
    background-color: var(--color-table-row-bg);
    border: solid 1px var(--color-table-row-bg-hover);
    border-radius: $border-radius-primary;

    .app-loader {
        top: -1px;
        left: -1px;
        right: -1px;
        bottom: -1px;
    }

    .header {
        display: flex;
        padding: 16px;
        background-color: var(--color-table-head-bg);

        .title,
        .total {
            font-weight: bold;
        }

        .title {
            text-transform: uppercase;
        }

        .total {
            margin-left: auto;
        }
    }

    .details {
        padding: 0 16px;

        .detail {
            display: flex;
            align-items: center;
            padding: 12px 0;
            border-bottom: 1px solid var(--color-divider);

            .status {
                padding: 4px 12px;
                font-weight: bold;
                border-radius: $border-radius-primary;

                @each $type in $table-tag-types {
                    &.#{ $type } {
                        color: map-get($table-tag-colors, $type);
                        background-color: map-get($table-tag-backgrounds, $type);
                    }
                }
            }

            .value {
                margin-left: auto;
            }

            &:last-child {
                border-color: transparent;
            }
        }
    }
}

@media (max-width: $tablet-size) {
    .app-summary-details {
        font-size: 14px;

        .header {
            padding: 16px 8px;
        }

        .details {
            padding: 0 8px;
        }
    }
}

@media (max-width: $mobile-size) {
    .app-summary-details {
        .header {
            padding: 8px;
        }

        .details {
            .detail {
                padding: 8px 0;

                .status {
                    padding: 2px 8px;
                    font-size: 12px;
                    line-height: 20px;
                }
            }
        }
    }
}
</style>