<template>
    <div class="page-main-dashboard">
        <app-loader v-if="loading" fixed-on="desktop" />

        <div class="container">
            <div class="row">
                <div class="col-12">
                    <div class="heading-box">
                        <h1 class="heading">Main Dashboard</h1>

                        <div class="form-group" v-if="is_info_available && is_main_spid">
                            <div class="form-controls">
                                <app-dropdown-select
                                    v-model="filter.query_params"
                                    :options="query_params_list"

                                    key-value="value"
                                    key-title="title"

                                    @change="onQueryParamsChange"
                                />
                            </div>

                            <div class="form-controls" v-if="show_specific_service_provider_select">
                                <app-dropdown-select
                                    v-model="filter.service_provider"
                                    :options="service_providers"

                                    key-value="value"
                                    key-title="title"

                                    label="Service Provider"

                                    :predictive-search="true"

                                    @change="getData()"
                                />
                            </div>
                        </div>
                    </div>

                    <p v-if="!is_info_available">Welcome to the portal. Please select an item from the left menu to proceed</p>

                    <app-error v-model="errors.show" :message="errors.message" />
                </div>

                <template v-if="loaded">
                    <div class="col-12 col-mob-12" v-if="has_role_customers">
                        <div class="board customers">
                            <h2 class="heading">Customers</h2>

                            <div class="row summary">
                                <div class="col-3 col-tab-6 col-mob-12">
                                    <app-summary
                                        status="info"

                                        icon="total"

                                        :title="customers.total"
                                        subtitle="Total"
                                    />
                                </div>

                                <div class="col-3 col-tab-6 col-mob-12">
                                    <app-summary
                                        status="success"

                                        icon="active"

                                        :title="customers.active"
                                        subtitle="Active"
                                    />
                                </div>

                                <div class="col-3 col-tab-6 col-mob-12">
                                    <app-summary
                                        status="danger"

                                        icon="inactive"

                                        :title="customers.inactive"
                                        subtitle="Inactive"
                                    />
                                </div>

                                <div class="col-3 col-tab-6 col-mob-12" v-if="show_customers_chart">
                                    <div class="doughnut-chart-container">
                                        <app-doughnut-chart
                                            :chart-data="customers_chart_data"
                                            css-classes="app-doughnut-chart"
                                        />

                                        <div class="doughnut-chart-legend">
                                            <div class="legend" v-for="(legend, index) in customers_chart_legend" :key="index" :class="[ legend.status ]">
                                                {{ legend.text }}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <router-link class="btn btn-primary" :to="{ name: 'customers' }">Customers</router-link>
                        </div>
                    </div>

                    <div class="col-12 col-mob-12">
                        <div class="board orders">
                            <div class="heading-with-actions">
                                <h2 class="heading">Orders</h2>

                                <div class="actions">
                                    <app-dropdown-select
                                        v-model="filter.time_period"
                                        :options="time_periods"

                                        key-value="value"
                                        key-title="title"

                                        label="Time period"

                                        @change="getData()"
                                    />

                                    <button class="btn btn-table-action action-refresh action-secondary" @click="getData"></button>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-12">
                                    <div class="line-chart-container">
                                        <app-line-chart
                                            :chart-data="orders_chart_data"
                                            css-classes="app-line-chart"
                                        />

                                        <div class="line-chart-legend">
                                            <div class="legend" v-for="(legend, index) in orders_chart_legend" :key="index">
                                                {{ legend.text }}
                                                <span class="line" :style="{ backgroundColor: legend.background_color }"></span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <!-- <router-link class="btn btn-primary" :to="{ path: '#' }">Reports</router-link> -->
                        </div>
                    </div>

                    <div class="col-6 col-mob-12" v-for="(product, key) in products" :key="key">
                        <div class="board">
                            <h2 class="heading">{{ product.title }}</h2>

                            <h5 class="heading">{{ product.services.title }}</h5>
                            <app-summary-details
                                title="Total"

                                :total="product.services.summary.total"

                                :rows="product.services.summary.rows"
                            />

                            <h5 class="heading">{{ product.orders.title }}</h5>
                            <app-summary-details
                                title="Total"

                                :total="product.orders.summary.total"

                                :rows="product.orders.summary.rows"
                            />

                            <router-link class="btn btn-primary" :to="product.link.to">{{ product.link.text }}</router-link>
                        </div>
                    </div>
                </template>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'

import appLoader from '@/components/app-loader'
import appError from '@/components/app-error'
import appSummary from '@/components/app-summary'
import appSummaryDetails from '@/components/app-summary-details'
import appDoughnutChart from '@/components/app-doughnut-chart'
import appLineChart from '@/components/app-line-chart'
import appDropdownSelect from '@/components/app-dropdown-select'

import errMessage from '@/helpers/errMessage'

import cloneDeep from 'lodash/cloneDeep'

const MONTHS = [
    {
        month: 'January',
        short_form: 'Jan',
    },
    {
        month: 'February',
        short_form: 'Feb',
    },
    {
        month: 'March',
        short_form: 'Mar',
    },
    {
        month: 'April',
        short_form: 'Apr',
    },
    {
        month: 'May',
        short_form: 'May',
    },
    {
        month: 'June',
        short_form: 'Jun',
    },
    {
        month: 'July',
        short_form: 'Jul',
    },
    {
        month: 'August',
        short_form: 'Aug',
    },
    {
        month: 'September',
        short_form: 'Sep',
    },
    {
        month: 'October',
        short_form: 'Oct',
    },
    {
        month: 'November',
        short_form: 'Nov',
    },
    {
        month: 'December',
        short_form: 'Dec',
    },
]

const COLORS = {
    vd: {
        customers: {
            active: 'rgba(0, 179, 71, 0.2)',
            inactive: 'rgba(230, 0, 0, 0.2)',
        },

        orders: {
            created: '#08b7d2',
            changed: '#00b347',
            terminated: '#fdd525',
        },
    },

    pb: {
        customers: {
            active: 'rgba(0, 179, 71, 0.2)',
            inactive: 'rgba(230, 0, 0, 0.2)',
        },

        orders: {
            created: '#08b7d2',
            changed: '#00b347',
            terminated: '#fdd525',
        },
    },

    iq: {
        customers: {
            active: 'rgba(4, 227, 8, 0.2)',
            inactive: 'rgba(255, 41, 26, 0.2)',
        },

        orders: {
            created: '#0bb3ff',
            changed: '#04e308',
            terminated: '#fdd525',
        },
    },
}

const services_mapping = data => ({
    total: data.Total,

    rows: [
        {
            key: {
                status: 'success',
                text: 'Active',
            },

            value: data.Active,
        },
        {
            key: {
                status: 'warning',
                text: 'Pending termination',
            },

            value: data.PendingTermination,
        },
        {
            key: {
                status: 'danger',
                text: 'Aborted',
            },

            value: data.Aborted,
        },
    ],
})

const orders_mapping = data => ({
    total: data.Total,

    rows: [
        {
            key: {
                status: 'success',
                text: 'New (Provision)',
            },

            value: data.Provisioning,
        },
        {
            key: {
                status: 'pending',
                text: 'Change (Modify)',
            },

            value: data.Change,
        },
        {
            key: {
                status: 'danger',
                text: 'Terminate',
            },

            value: data.Terminating,
        },
    ],
})

const AVAILABLE_PRODUCTS = {
    PROD_FIBRE: {
        config: {
            title: 'Fibre Broadband',

            link: {
                to: { name: 'fibre-services' },
                text: 'Fibre Broadband',
            },

            services: {
                title: 'Fibre Services',
            },

            orders: {
                title: 'Fibre Orders',
            },
        },

        alias: 'fibre',
    },

    PROD_FWA: {
        config: {
            title: 'Fixed Wireless Products',

            link: {
                to: { name: 'fwa-services' },
                text: 'Wireless Products',
            },

            services: {
                title: 'Fixed Wireless Services',
            },

            orders: {
                title: 'Fixed Wireless Orders',
            },
        },

        alias: 'FWA',
    },

    PROD_MOBILE: {
        config: {
            title: 'Postpay Mobile',

            link: {
                to: { name: 'postpay-mobile-services' },
                text: 'Postpay Mobile',
            },

            services: {
                title: 'Mobile PostPay Services',
            },

            orders: {
                title: 'Mobile PostPay Orders',
            },
        },

        alias: 'postpaymobile',
    },

    PREPAY_MOBILE: {
        config: {
            title: 'Prepay Mobile',

            link: {
                to: { name: 'prepay-mobile-services' },
                text: 'Prepay Mobile',
            },

            services: {
                title: 'Prepay Mobile Services',
            },

            orders: {
                title: 'Prepay Mobile Orders',
            },
        },

        alias: 'prepaymobile',
    },
}

export default {
    components: {
        appLoader,
        appError,
        appSummary,
        appSummaryDetails,
        appDoughnutChart,
        appLineChart,
        appDropdownSelect,
    },

    data() {
        return {
            filter: {
                service_provider: null,

                time_period: 2,

                query_params: 'complete',
            },

            loading: false,

            loaded: false,

            data: null,

            errors: {
                show: false,
                message: '',
            },
        }
    },

    created() {
        this.init()
    },

    methods: {
        init() {
            if (this.is_info_available) {
                this.getData()
            }
        },

        onQueryParamsChange() {
            // console.warn('onQueryParamsChange() {')

            if (this.show_specific_service_provider_select) {
                if (this.filter.service_provider) {
                    this.getData()
                }
            } else {
                this.getData()
            }
        },

        getData() {
            this.loading = true

            let params = {
                SPID: this.current_spid,
            }

            // console.log('this.filter.time_period', this.filter.time_period)

            if (this.filter.time_period) {
                const index = this.filter.time_period - 1

                // console.log('index', index)

                params = {
                    ...params,

                    ...(this.time_periods[index].params),
                }
            }

            if (this.is_main_spid) {
                const key = this.filter.query_params

                // console.log('key', key)

                // console.log('this.query_params_list[key]', this.query_params_list[key])

                params = {
                    ...params,

                    ...(this.query_params_list[key].params()),
                }
            }

            console.log('params', params)

            // import('./dashboard.json')
            // this.$store.dispatch('dashboard/getData', params)
            this.$store.dispatch('api_reporting/Dashboard', params)
                .then(response => {
                    // console.log('response', JSON.parse(JSON.stringify(response)))

                    this.data = response

                    this.errors.show = false
                    this.errors.message = ''

                    this.loading = false

                    this.loaded = true
                })
                .catch(() => {
                    this.$router.push({ name: 'main-dashboard-v1' })
                })
        },
    },

    computed: {
        ...mapGetters([
            'spid',
            'current_spid',
            'available_service_providers',

            'modules_user',
            'isModuleAvailable',

            'is_super_admin',
            'current_sp_roles',

            'is_prerelease_mode',

            'theme',
        ]),

        main_spid() {
            return +this.spid
        },

        is_main_spid() {
            return this.current_spid == this.main_spid
        },

        service_providers() {
            return this.available_service_providers.map(provider => ({
                value: provider.SPID,
                title: provider.Name,
            }))
        },

        time_periods() {
            const time_periods = [
                {
                    value: 1,
                    title: '3 months',
                    params: {
                        Weeks: 12,
                    },

                    /*
                        params: {
                            Months: 3,
                        },
                    */
                },
                {
                    value: 2,
                    title: '6 months',
                    params: {
                        Fortnights: 12,
                    },

                    /*
                        params: {
                            Months: 6,
                        },
                    */
                },
                {
                    value: 3,
                    title: 'Last year',
                    params: {
                        Months: 12,
                    },
                },
                {
                    value: 4,
                    title: 'Since inception',
                    params: {
                        Years: 12,
                    },
                },
            ]

            return time_periods
        },

        query_params_list() {
            const query_params_list = {
                complete: {
                    value: 'complete',
                    title: 'All service providers',
                    params: () => ({ Complete: true }),
                },

                inverted: {
                    value: 'inverted',
                    title: 'All other service providers',
                    params: () => ({ Inverted: true }),
                },

                specific: {
                    value: 'specific',
                    title: 'Specific service provider',
                    params: () => ({ SPID: this.filter.service_provider || this.current_spid }),
                },
            }

            return query_params_list
        },

        show_specific_service_provider_select() {
            return this.filter.query_params == 'specific'
        },

        customers() {
            return {
                total:    this.data.Customers.Total,
                active:   this.data.Customers.Active,
                inactive: this.data.Customers.Inactive,
            }
        },
        show_customers_chart() {
            return this.customers.total > 0
        },
        customers_chart_data() {
            return {
                datasets: [{
                    data: [
                        this.customers.active,
                        this.customers.inactive,
                    ],

                    backgroundColor: [
                        COLORS[this.theme].customers.active,
                        COLORS[this.theme].customers.inactive,
                    ],
                }],
            }
        },
        customers_chart_legend() {
            const active_percentage = Math.round(this.customers.active / (this.customers.active + this.customers.inactive) * 100)
            const inactive_percentage = 100 - active_percentage

            const legend = [
                {
                    status: 'success',
                    text: `Active ${ active_percentage }%`,
                },
                {
                    status: 'danger',
                    text: `Inactive ${ inactive_percentage }%`,
                },
            ]

            return legend
        },

        orders() {
            return {
                created:    this.data.Orders.Created,
                changed:    this.data.Orders.Changed,
                terminated: this.data.Orders.Terminated,
            }
        },
        orders_chart_data() {
            const labels = []

            const date = new Date()

            date.setDate(1)

            for (let i = 0; i < this.orders.created.length; i++) {
                labels.push(`${ MONTHS[date.getMonth()].short_form } ${ date.getFullYear() }`)
                date.setMonth(date.getMonth() - 1)
            }

            labels.reverse()

            const default_options = {
                pointRadius: 4,
                pointHoverRadius: 4,

                borderWidth: 2,
                hoverBorderWidth: 2,

                lineTension: 0,
            }

            const getColors = color => ({
                backgroundColor: color,
                hoverBackgroundColor: color,

                borderColor: color,
                hoverBorderColor: color,
            })

            return {
                labels,

                datasets: [
                    {
                        data: this.orders.created.reverse(),
                        ...getColors(COLORS[this.theme].orders.created),
                        ...default_options,
                    },
                    {
                        data: this.orders.changed.reverse(),
                        ...getColors(COLORS[this.theme].orders.changed),
                        ...default_options,
                    },
                    {
                        data: this.orders.terminated.reverse(),
                        ...getColors(COLORS[this.theme].orders.terminated),
                        ...default_options,
                    },
                ],
            }
        },
        orders_chart_legend() {
            const legend = [
                {
                    background_color: COLORS[this.theme].orders.created,
                    text: 'New orders',
                },
                {
                    background_color: COLORS[this.theme].orders.changed,
                    text: 'Changed orders',
                },
                {
                    background_color: COLORS[this.theme].orders.terminated,
                    text: 'Terminated orders',
                },
            ]

            return legend
        },

        is_info_available() {
            let is_module_available = false

            for (const key in this.available_modules) {
                is_module_available = this.available_modules[key]

                if (is_module_available) {
                    break
                }
            }

            return this.has_role_customers || is_module_available
        },

        available_modules() {
            return {
                PROD_FIBRE:    Boolean(this.modules_user.PROD_FIBRE)    && this.isModuleAvailable('PROD_FIBRE')    && this.has_role_fibre,
                PROD_FWA:      Boolean(this.modules_user.PROD_FWA)      && this.isModuleAvailable('PROD_FWA')      && this.has_role_fwa,
                PROD_MOBILE:   Boolean(this.modules_user.PROD_MOBILE)   && this.isModuleAvailable('PROD_MOBILE')   && this.has_role_postpay,
                PREPAY_MOBILE: Boolean(this.modules_user.PREPAY_MOBILE) && this.isModuleAvailable('PREPAY_MOBILE') && this.has_role_prepay && this.is_prerelease_mode,
            }
        },

        products() {
            const products = {}

            for (const key in this.available_modules) {
                if (this.available_modules[key]) {
                    const data = this.data.Products.Type[key] || this.data.Products.Type[AVAILABLE_PRODUCTS[key].alias]

                    if (data) {
                        products[key] = cloneDeep(AVAILABLE_PRODUCTS[key].config)

                        products[key].services.summary = services_mapping(data.Services)
                        products[key].orders.summary = orders_mapping(data.Orders)
                    }
                }
            }

            return products
        },

        has_role_customers() {
            return this.is_super_admin || this.current_sp_roles.includes('READ') || this.current_sp_roles.includes('WRITE')
        },

        has_role_fibre() {
            return this.is_super_admin || this.current_sp_roles.includes('PROD_FIBRE') || this.current_sp_roles.includes('FIBRE.READ') || this.current_sp_roles.includes('FIBRE.MANAGE')
        },
        has_role_fwa() {
            return this.is_super_admin || this.current_sp_roles.includes('PROD_FWA') || this.current_sp_roles.includes('FWA.READ') || this.current_sp_roles.includes('FWA.MANAGE')
        },
        has_role_postpay() {
            return this.is_super_admin || this.current_sp_roles.includes('PROD_MOBILE') || this.current_sp_roles.includes('MOBILE.READ') || this.current_sp_roles.includes('MOBILE.MANAGE')
        },
        has_role_prepay() {
            return this.is_super_admin || this.current_sp_roles.includes('PROD_PREPAY') || this.current_sp_roles.includes('PREPAY.READ') || this.current_sp_roles.includes('PREPAY.MANAGE')
        },
    },
}
</script>

<style lang="scss">
.page-main-dashboard {
    padding-bottom: 80px;

    .heading-box {
        // background-color: rgba(#f00, 0.2);

        display: flex;
        align-items: center;
        justify-content: space-between;

        margin-bottom: 48px;

        h1 {
            &.heading {
                margin: 0 16px 0 0;
            }
        }

        .form-group {
            // background-color: rgba(#00f, 0.2);

            display: flex;
            flex-direction: column;

            width: 100%;
            max-width: 375px;

            padding: 24px;

            background-color: var(--color-component-bg-primary);
            border-radius: $border-radius-primary;
            box-shadow: var(--box-shadow-secondary);

            .form-controls {
                margin-bottom: 24px;

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }

    .app-error {
        margin-bottom: 24px;
    }

    & > .container {
        & > .row {
            margin-bottom: -30px;

            & > .col-mob-12 {
                margin-bottom: 30px;
            }
        }
    }

    .board {
        padding: 24px;

        background-color: var(--color-component-bg-primary);
        border-radius: $border-radius-primary;
        box-shadow: var(--box-shadow-secondary);

        & > h2 {
            &.heading {
                margin-bottom: 24px;
            }
        }

        & > h5 {
            &.heading {
                margin-bottom: 16px;

                font-weight: bold;
            }
        }

        .heading-with-actions {
            display: flex;
            align-items: center;
            justify-content: space-between;

            margin-bottom: 24px;

            .actions {
                display: flex;
                align-items: center;

                width: 100%;
                max-width: 275px;

                margin-left: 16px;

                .app-dropdown-select {
                    margin-right: 16px;
                }

                .action-refresh {
                    transform: rotateY(180deg);
                }
            }
        }

        .doughnut-chart-container {
            display: flex;

            .app-doughnut-chart {
                max-width: 160px;
            }

            .doughnut-chart-legend {
                display: flex;
                flex-direction: column;
                justify-content: center;
                margin-left: 24px;

                .legend {
                    margin-bottom: 24px;
                    padding: 4px 12px;
                    font-weight: bold;
                    text-align: center;
                    border-radius: $border-radius-primary;

                    @each $type in $table-tag-types {
                        &.#{ $type } {
                            color: map-get($table-tag-colors, $type);
                            background-color: map-get($table-tag-backgrounds, $type);
                        }
                    }

                    &:last-child {
                        margin-bottom: 0;
                    }
                }
            }
        }

        .line-chart-container {
            .line-chart-legend {
                display: flex;
                margin-top: 24px;

                .legend {
                    position: relative;
                    margin: 0 48px 0 0;
                    padding-right: 8px + 48px;
                    font-weight: bold;

                    .line {
                        position: absolute;
                        top: 50%;
                        right: 0;

                        transform: translateY(-50%);

                        display: block;

                        width: 48px;
                        height: 2px;

                        border-radius: 2px;

                        &::after {
                            position: absolute;
                            top: 50%;
                            right: 50%;

                            transform: translate(50%, -50%);

                            display: block;

                            width: 8px;
                            height: 8px;

                            border-radius: 50%;

                            content: '';

                            background-color: inherit;
                        }
                    }

                    &:last-child {
                        margin: 0;
                    }
                }
            }
        }

        .app-summary-details {
            margin-bottom: 30px;
        }

        & > a {
            margin-top: 30px;
        }

        & > .row {
            margin: 0 -#{ $container-padding / 2 };
        }
    }
}

@media (max-width: $tablet-size) {
    .page-main-dashboard {
        .board {
            padding: 16px;

            & > h2 {
                &.heading {
                    margin-bottom: 16px;
                }
            }

            .heading-with-actions {
                margin-bottom: 16px;
            }

            .doughnut-chart-container {
                .doughnut-chart-legend {
                    .legend {
                        font-size: 14px;
                    }
                }
            }

            .line-chart-container {
                .line-chart-legend {
                    .legend {
                        margin: 0 40px 0 0;
                        font-size: 14px;
                    }
                }
            }

            &.customers {
                .row {
                    &.summary {
                        margin-bottom: -30px;

                        .col-mob-12 {
                            margin-bottom: 30px;
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: $mobile-size) {
    .page-main-dashboard {
        padding-bottom: 64px;

        .heading-box {
            flex-direction: column;
            align-items: flex-start;

            margin-bottom: 16px;

            h1 {
                &.heading {
                    margin: 0 0 32px 0;
                }
            }

            .form-group {
                max-width: 100%;

                padding: 16px 8px;
            }
        }

        & > .container {
            & > .row {
                margin-bottom: -15px;

                & > .col-mob-12 {
                    margin-bottom: 15px;
                }
            }
        }

        .board {
            padding: 16px 8px;

            .doughnut-chart-container {
                .doughnut-chart-legend {
                    margin-left: 14px;

                    .legend {
                        margin-bottom: 16px;
                        line-height: 20px;
                    }
                }
            }

            .line-chart-container {
                .line-chart-legend {
                    flex-direction: column;
                    margin-top: 18px;

                    .legend {
                        margin: 0 0 16px 0;
                    }
                }
            }

            & > a {
                margin-top: 24px;
            }

            & > .row {
                margin: 0 -#{ $container-padding-mobile / 2 };
            }

            &.customers {
                .row {
                    &.summary {
                        margin-bottom: -16px;

                        .col-mob-12 {
                            margin-bottom: 16px;
                        }
                    }
                }
            }
        }
    }
}
</style>