<template>
    <div class="app-summary" :class="[ status ]">
        <i :class="[ icon ]"></i>

        <span class="title">{{ title }}</span>

        <span class="subtitle">{{ subtitle }}</span>
    </div>
</template>

<script>
export default {
    props: {
        status: { type: String, default: 'success' }, // src\assets\scss\_table-tag-colors.scss

        icon: { type: String, default: 'active' }, // total, active, inactive

        title: { type: [String, Number], required: true },

        subtitle: { type: String, required: true },
    },
}
</script>

<style lang="scss">
.app-summary {
    display: flex;
    flex-direction: column;
    align-items: center;

    padding: 24px;

    border-radius: $border-radius-secondary;

    i {
        display: block;

        width: 24px;
        height: 24px;

        margin-bottom: 8px;

        @include icon-before($icon-summary-default);

        @each $type, $code in $icons-summary-types {
            &.#{ $type } {
                @include icon-before-code($code);
            }
        }
    }

    .title {
        margin-bottom: 8px;

        font-size: 40px;
        line-height: 48px;
        font-weight: bold;
    }

    .subtitle {
        font-size: 18px;
        line-height: 24px;
    }

    @each $type in $table-tag-types {
        &.#{ $type } {
            color: map-get($table-tag-colors, $type);

            background-color: map-get($table-tag-backgrounds, $type);
        }
    }

    &.held,
    &.info {
        color: #333;
    }
}

@media (max-width: $mobile-size) {
    .app-summary {
        padding: 16px;

        .title {
            font-size: 32px;
            line-height: 40px;
        }
    }
}
</style>