<script>
import { Doughnut, mixins } from 'vue-chartjs'
const { reactiveProp } = mixins

const DEFAULT_OPTIONS = {
    /*
        animation: {
            animateRotate: false,
        },
    */

    cutoutPercentage: 15,

    legend: {
        display: false,
    },

    tooltips: {
        enabled: false,
    },

    elements: {
        arc: {
            borderWidth: 0,
        },
    },
}

export default {
    extends: Doughnut,

    mixins: [reactiveProp],

    props: {
        chartOptions: { type: Object, default: () => {} },
    },

    mounted () {
        this.renderChart(this.chartData, this.options)
    },

    computed: {
        options() {
            return {
                ...DEFAULT_OPTIONS,
                ...this.chartOptions,
            }
        },
    },
}
</script>