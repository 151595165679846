<script>
import { Line, mixins } from 'vue-chartjs'
const { reactiveProp } = mixins

const DEFAULT_OPTIONS = {
    legend: {
        display: false,
    },

    tooltips: {
        enabled: false,
    },

    elements: {
        line: {
            fill: false,
        },
    },

    scales: {
        yAxes: [
            {
                ticks: {
                    beginAtZero: true,
                    fontColor: '#999999',
                },

                gridLines: {
                    color: '#cccccc',
                    zeroLineColor: '#cccccc',
                },
            },
        ],

        xAxes: [
            {
                ticks: {
                    fontColor: '#999999',
                },

                gridLines: {
                    color: '#cccccc33',
                    zeroLineColor: '#cccccc',
                },
            },
        ],
    },

    maintainAspectRatio: false,
}

export default {
    extends: Line,

    mixins: [reactiveProp],

    props: {
        chartOptions: { type: Object, default: () => {} },
    },

    mounted () {
        this.renderChart(this.chartData, this.options)
    },

    computed: {
        options() {
            return {
                ...DEFAULT_OPTIONS,
                ...this.chartOptions,
            }
        },
    },
}
</script>